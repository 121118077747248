export const TRANSCRIPTS: TranscriptsConfigProps = {
  default: [
    {
      transcript: 'Hello Sky',
      type: 'WW',
      repeats: 3
    },
    {
      transcript: 'Hello Sky, TV guide',
      type: 'Phrase'
    },
    {
      transcript: 'Hello Sky, show me comedy movies',
      type: 'Phrase'
    }
  ],
  kids: [
    {
      transcript: 'Hello Sky',
      type: 'WW',
      repeats: 2
    },
    {
      transcript: 'Hello Sky, test phrase 1',
      type: 'Phrase'
    },
    {
      transcript: 'Hello Sky, test phrase 2',
      type: 'Phrase'
    }
  ]
}
